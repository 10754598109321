.formBtn {
    background-color: #a493b7;
    border-radius: 15px;
    border: 0;
    outline: 0;
    padding: 8px 12px;
    font-size: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: center;
    margin-bottom: 20px;
  
  }

  .modalContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btnContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .confirmationText1{
    text-align: center;
    font-weight:400;
    font-size:20px;
    /* margin: 0; */
  }

  .redBtn{
    background-color: #710000;
  }
 