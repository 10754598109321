@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.nav {
  position: fixed;
  width: 100%;
  background-color: #003333;
  z-index: 999;
}

.navHeader {
  max-width: 1222px;
  width: 96%;
  margin: auto;
  height: 100px;
  overflow: visible;
  /* position: fixed; */
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 42px;
}

.filterContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background: #194747;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 8px 8px;
  flex: 1;
}

.filter {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  font-size: 14px;
  width: 100%;
}

.filter::placeholder {
  color: #fff;
  font-size: 14px;
}

.clearIcon {
  cursor: pointer;
  color: #fff;
  position: absolute;
  display: flex;
  right: 10px;
}

.upperHeader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
}

.brandText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
  /* flex-grow: 1; */
  flex-shrink: 0;
  width: 80%;
}
.BounderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.oneLogo {
  width: 65px;
  height: 65px;
}

.brand {
  font-size: 52px;
  font-weight: 300;
  letter-spacing: -5px;
  color: #fff;
  flex-shrink: 0;
  font-family: "Be Vietnam Pro", sans-serif !important;
  line-height: 52px;
}

.subBrand {
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 400;
  font-family: "Average Sans";
  margin-top: -6px;
  white-space: nowrap;
}

.userCover {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
}

.navLink {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .navHeader {
    padding: 0 30px;
    flex-wrap: wrap-reverse;
  }

  .filterContainer {
    width: 150px;
    flex-basis: auto;
    margin: auto;
    justify-content: flex-start;
    /* margin-right: 10px; */
  }

  .upperHeader {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  .brandText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .oneLogo {
    width: 55px;
    height: 55px;
  }

  .brand {
    font-size: 45px;
  }

  .userCover {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 431px) {
  .navHeader {
    padding: 20px 10px;
    height: auto;
    gap: 10px;
    flex-wrap: wrap-reverse;
  }

  .filterContainer {
    width: 150px;
    flex-basis: auto;
    margin: auto;
    justify-content: flex-start;
    padding: 4px 4px;
    gap: 4px;
  }

  .oneLogo {
    width: 40px;
    height: 40px;
  }
  .filter {
    font-size: 10px;
  }

  .filter::placeholder {
    font-size: 10px;
  }

  .brand {
    font-size: 30px;
  }
  .brandText {
    align-items: flex-end;
  }

  .userCover {
    width: 40px;
    height: 40px;
  }
  .subBrand {
    font-size: 10px;
    margin-top: -15px;
  }
}
