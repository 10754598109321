.headerHeight {
  height: 12px;
}

.background {
  /* overflow-y: scroll; */
  /* background-color: #fff; */
}

.inputForm {
  width: 223px;
  border-color: #000000;
  border-radius: 8px;
  height: 40px;
  padding: 5px;
  padding: 1%;
  font-weight: 400;
  font-size: 14px;
  /* color: #8B8888; */
  border-width: 1px;
  background-color: #ededed;
  flex: 1;
}

input.inputForm:focus {
  border-color: #000000;
  border-radius: 8px;
  border-width: 1px;
  outline: 1px solid #000000;
}

.background {
  display: flex;
  gap: 10px;
  flex-direction: column;
  /* display: flex; */
  /* flex-direction: row; */

  /* background-color: #fff; */
}

.textArea_postBody {
  flex: 1;
  width: 299px;
  height: 101px;
  border-radius: 8px;
  background: #ededed;
  padding: 2%;
}

.textArea_postBody:focus {
  outline: 1px solid #000000;
  padding: 2%;
}

.btnImg {
  color: #8b8888;
}

.postImage {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}

.offerBtn {
  border-color: #000000;
  border-radius: 8px;
  height: 40px;
  padding: 8px;
  /* margin-left: 10px; */
  border-width: 1px;
}

.userWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  gap: 5px;
  max-height: 300px;
  overflow-y: auto;
}

.dialogItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: baseline;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.userIconWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.posRelative {
  position: relative;
}

.iconCross {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: #cbcaca;
  color: #000;
  backdrop-filter: blur(10px);
  border-radius: 50%;
  cursor: pointer;
}

.userimage {
  width: 35px;
  height: 35px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 1px solid #fff;
}

.wfull {
  width: 100%;
}

.bgTransparent {
  background-color: transparent !important;
  border: 0;
  outline: 0;
  width: 100%;
}

.flexGrow1 {
  flex: 1;
}

.mapInput {
  width: 100%;
}

.EachContainer {
  display: flex;
  gap: 10px;
  width: 100%;
}

.EachContainer2 {
  display: flex;
  gap: 10px;
  flex: 1;
  /* width: 100% */
}

.valueContainer1 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.Menucontainer {
  display: flex;
  width: 30%;
  justify-content: center;
  cursor: pointer;
  /* gap:10 */
}

.menuText {
  margin-left: 10px;
}
.greenMenuText {
  margin-left: 10px;
  color: #007112;
}

.addIcon {
  background-color: #007112;
  /* width:5vw;
    height: 5vw; */
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.addIconInnerStyle {
  height: 20px;
  width: 20px;
  /* width: 2vw;
    height: 2vw; */
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  background-color: #007112;
  color: #fff;
  padding: 1% 2%;
  border-radius: 18px;
}

.greenText {
  color: #007112;
}

@media only screen and (max-width: 600px) {
  .EachContainer {
    flex-direction: column;
    row-gap: 0;
  }
}
