/* LoaderComponent.module.css */
.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999;
}

.customLoader {
  width: 60px;
  height: 60px;
  display: grid;
  border: 4px solid transparent;
  border-radius: 50%;
  border-color: #e4e4ed transparent;
  animation: s6 1s infinite linear;
  z-index: 99;
}

@media only screen and (max-width: 768px) {
  .customLoader {
    width: 47.5px;
    height: 47.5px;
  }
}
@media only screen and (max-width: 431px) {
  .customLoader {
    width: 35px;
    height: 35px;
  }
}

.customLoader::before,
.customLoader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}

.customLoader::before {
  border-color: #db9791 transparent;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.customLoader::after {
  margin: 8px;
}

@keyframes s6 {
  100% {
    transform: rotate(1turn);
  }
}
