.container {
  display: flex;
  /* flex-direction: column; */
}
.page {
  margin-top: 100px;

  /* display: flex; */
}

.title {
  color: #000;
  /* text-align: center; */
  font-weight: 500px;
  font-size: 34px;
  line-height: 47.67px;
  color: #fff;
  margin-bottom: 2%;
  display: inline-block;
  width: 100%;
}

.mainDiv {
  width: 100%;
  min-height: inherit;

  /* background-color: #FFFFFF; */
  /* background-color: #e6e6e6; */
}

.img {
  /* width: 18vw; */
  border-radius: 50%;
  padding: 3px;
  background-color: #fff;
  margin-left: 0.1vw;

  width: 129px;
  height: 129px;
  /* height: 10vw; */
  /* height: 50px; */
}

.info {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  justify-content: space-between;
  max-height: 110px;
}

.member_name {
  color: #000000;
  /* font-size: 13px;
  font-weight: 300;
  line-height: 17.71px; */
  font-size: 16px;
  font-weight: 300;
  line-height: 17.71px;
}

.event_name {
  color: #020000;
  /* font-size: 1rem; */
  /* font-size: 15px;
  font-weight: 500px;
  line-height: 20.43px;

  font-weight: 300px;
  line-height: 17.71px; */
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.member_status {
  color: #020000;
  font-size: 13px;
  font-weight: 300;
  max-width: 182px;
  height: inherit;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* margin: auto; */
}

.button {
  border: 0;
  outline: 0;
  max-width: 98%;
  display: block;
  width: 98%;
  margin: auto;
  /* background-color: #eab9b4; */
  font-size: 13px;
  font-weight: 400;
  color: #000;
  height: 35px;
  border-radius: 20px;
  margin-top: -20px;
  background-color: #eab9b4;
  /* z-index:-3px */
}
.greenbutton {
  border: 0;
  outline: 0;
  max-width: 98%;
  display: block;
  width: 98%;
  margin: auto;
  /* background-color: #eab9b4; */
  font-size: 13px;
  font-weight: 400;
  color: #000;
  height: 35px;
  border-radius: 20px;
  margin-top: -20px;
  background-color: #c9eab4;
  /* z-index:-3px */
}

.price {
  color: #000;
  margin-left: auto;
  margin-right: 2%;
}

.infinitescroll {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pageHeader {
  /* max-width: 500px; */
  align-self: center;
  margin-right: auto;
  margin-left: auto;
  width: 500px;
}

@media only screen and (max-width: 768px) {
  .img {
    width: 140px;
    height: 140px;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-left: 2.4vw;
    justify-content: space-around;
    max-height: 120px;
  }
  .button {
    /* margin-top: -px; */
    /* z-index:-3px */
  }
}

@media only screen and (max-width: 431px) {
  .img {
    width: 92px;
    height: 92px;
    border-width: 3px;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-left: 2.4vw;
    justify-content: space-around;
    max-height: 150px;
  }
  .button {
    margin-top: -2vw;
    /* z-index:-3px */
  }

  .title {
    font-size: 25px;
  }

  .pageHeader {
    text-align: center;
    width: 97%;
  }
}
