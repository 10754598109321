.passIcon {
  border: 0;
  outline: 0;
  min-width: 40px;
  height: 40px;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  right: 0;
  margin: 8px 0px 0px 0px;
}
.passIcon .eye {
  width: 1.5rem;
  height: 1.5rem;
  color: #171717;
}
