@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.nav {
  position: fixed;
  width: 100%;
  background-color: #003333;
  z-index: 999;
}

.navHeader {
  max-width: 1222px;
  width: 96%;
  margin: auto;
  height: 100px;
  overflow: visible;
  /* position: fixed; */
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 62px;
}

.oneLogo {
  width: 65px;
  height: 65px;
}

.brand {
  font-size: 52px;
  font-weight: 300;
  letter-spacing: -5px;
  color: #fff;
  line-height: 52px;
  font-family: "Be Vietnam Pro", sans-serif !important;
}

.subBrand {
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 400;
  font-family: "Average Sans";
  margin-top: -6px;
  white-space: nowrap;
}

.userCover {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
}

.navLink {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .brand {
    font-size: 45px;
  }
  .oneLogo {
    width: 55px;
    height: 55px;
  }
  .userCover {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 431px) {
  .brand {
    font-size: 30px;
  }
  .oneLogo {
    width: 40px;
    height: 40px;
  }
  .userCover {
    width: 40px;
    height: 40px;
  }
  .navHeader {
    height: 90px;
    padding: 0 25px;
  }
  .subBrand {
    font-size: 10px;
    margin-top: -10px;
  }
  .brandText {
    align-items: flex-end;
  }
}

@media only screen and (max-width: 325px) {
  .brand {
    font-size: 35px;
  }
  .oneLogo {
    width: 40px;
    height: 40px;
  }
  .userCover {
    width: 40px;
    height: 40px;
  }
}
