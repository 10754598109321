.stickyFooter {
    background-color: #003333;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1%;
    /* min-height: 25%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 3;
  }
  
  .fonts{
    /* font-size:25px; */
    /* font-weight: 800; */
    background-color: #27B836;
    padding: 1%;
    /* padding: 15px; */
    border-radius: 100%;

  }

  .img{
    height: 20px;
    width: 20px; 
    
  }


.wModal {
  max-width: 363px !important;
}

.greenBorder{
  border: 2px solid green;
  padding: 20px;
  border-radius: 20.96px;
  display: flex;
  flex-direction: column;
  /* width: 80px; */
  width: min-content;
}

.ModalImg{
  height: 40px;
  width: 40px; 
}

.subContainer{
  display: flex;
  justify-content: space-around;

}

.eventProducerContainer{
  background-color: #DB9791;
  width: 80%;
  height: 10vh;
  border-radius: 14px;
  color: #fff;
  font-size: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.eventPlayerContainer{
  background-color: #A5C4AA;
  width: 80%;
  height: 10vh;
  border-radius: 14px;
  color: #fff;
  font-size: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalContainer{
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.textContainer{
  width: 80%;
  text-align: center;
  margin-top: 3%;
}

.textContainerDefaultText{
  width: 80%;
  text-align: center;
  margin-top: 3%;
  text-decoration: underline;
}

.signup{
  background-color: #DB9791;
  width: 60%;
  height: 8vh;
  border-radius: 14px;
  color: #fff;
  font-size: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.signupGreen{
  background-color:#A5C4AA;
  width: 60%;
  height: 8vh;
  border-radius: 14px;
  color: #fff;
  font-size: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.cancel{
  background-color: #710000;
  text-align: center;
  font-size: 1rem;
}