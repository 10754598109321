.mainDiv {
  width: 100%;
  min-height: inherit;
  /* background-color: #e6e6e6; */
  display: flex;
  flex-direction: column;
}



.formContainer {
  margin: 150px auto 25px auto !important;
  width: 100%;
  min-width: 600px;
  max-width: 600px;
  border: 1px solid #a5c4aa;
  border-radius: 20px;
  
}

.backButton {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 5;
  border: 0;
  outline: 0;
  background-color: #6f1111;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.profileBackContainer {
  width: 100%;
  min-height: 177px;
}

.profileBackImage {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  max-height: 177px;
  min-height: 177px;
  object-fit: cover;
}

.profileImageContainer {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  margin-bottom: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.profileImage {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #fff;
  min-width: 100%;
  min-height: 100%;
}

.profileItem {
  display: flex;
  width: 100%;
}

.profileLable {
  font-weight: bold;
  min-width: 30%;
}

.profileField {
  margin: 5px;
  width: 100%;
}

.linkPayBtn {
  background-color: #8058ae;
  border-radius: 15px;
  border: 0;
  outline: 0;
  padding: 5px 10px 5px 10px;
  font-size: 13px;
  color: #fff;
  display: flex;
  align-items: center;
}

.formBtnDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12px;

}

.formBtn {
  background-color: #a493b7;
  border-radius: 15px;
  border: 0;
  outline: 0;
  padding: 8px 12px;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: center;
}

.formBtn:hover {
  background-color: #fff;
  color: #a493b7;
}

.linkPayIcon {
  width: 22px;
  height: 13px;
  margin: 0 4px 0 0;
}

.inputWrapper {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  flex-direction: column;
}

.formDiv {
  width: 100%;
  padding: 20px;
}

.inputField {
  padding: 0 15px 0 15px;
}

.profileTitle {
  font-weight: bold;
  font-size: 24px;
}

.profileTitleItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.profileSubTitleItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.profileSubTitle {
  font-size: 14px;
}

.error {
  font-family: "Average Sans", sans-serif;
  color: red;
  font-size: 11px;
  margin: 4px 21px 0px 15px;
}

.connectBtn {
  justify-content: center;
  align-items: center;
  display: flex;
}

.imageSection {
  width: 100%;
  position: relative;
  margin-bottom: 80px;
}

.editImageIcon {
  position: absolute;
  color: #6f1111;
  right: 0;
  cursor: pointer;
  font-size: 30px;
  margin: 7px;
  background: #e6e6e6;
  border-radius: 50%;
  padding: 4px;
}

.editProfileImgIcon {
  position: absolute;
  color: #6f1111;
  right: -8px;
  cursor: pointer;
  font-size: 30px;
  margin: 7px;
  background: #e6e6e6;
  border-radius: 50%;
  padding: 4px;
}

.skillChip {
  font-size: 13px;
  background: #c4a479;
  border-radius: 20px;
  padding: 7px 10px;
  color: #fff;
  display: flex;
  gap: 2px;
  justify-content: center;
}

.skillChipDiv {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 3px;
}

.chipDelete {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 2px;
}

.blankCover {
  min-height: 150px;
  /* background: #6f1111; */
  border-radius: 20px 20px 0 0;
}

.memberDiv {
  margin: 5px 5px 10px 5px;
}

.memberLbl {
  font-size: 14px;
  margin-bottom: 5px;
}

.memberbtn {
  font-size: 14px;
  background: #a5c4aa;
  outline: 0;
  color: #fff;
  border-radius: 20px;
  border: 1px solid #a5c4aa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playerBtn {
  height: 29px;
  width: 84px;
}
.eventProdBtn {
  height: 29px;
  width: 139px;
  margin-left: 5px;
  
}

.eventProdBtnIcon {
  width: 17px;
  height: 18px;
  margin-right: 3px;
}

.eventPlayerBtnIcon {
  width: 12px;
  height: 22px;
  margin-right: 3px;
}

.btnSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1300px) {
  .formContainer {
    min-width: 500px;
    max-width: 500px;
  }

  .profileBackImage {
    max-height: 144px;
    min-height: 144px;
  }

  .profileBackContainer {
    min-height: 144px;
  }
}
@media only screen and (max-width: 431px) {
  .formContainer {
    min-width: 98%;
    width: 98%;
  }
  .profileItem {
    width: 100%;
  }

  .profileLable {
    min-width: auto;
  }

  .profileField {
    display: flex;
    overflow-wrap: anywhere;
    justify-content: center;
    flex-direction: column;
  }

  .profileFieldItem {
    flex-direction: column;
    align-items: flex-start;
  }

  .profileInputField {
    margin-left: 0;
    margin-top: 5px;
  }

  .imageSection {
    margin-bottom: 35px;
  }

  .profileImageContainer {
    width: 90px;
    height: 90px;
  }

  .editImageIcon {
    font-size: 25px;
  }
  .editProfileImgIcon {
    font-size: 25px;
    right: -13px;
  }

  .blankCover {
    min-height: 120px;
  }

  .profileBackImage {
    max-height: 110px;
    min-height: 110px;
  }

  .profileBackContainer {
    min-height: 110px;
  }
}

.ActiveBorderColor{
  border: 3px solid #000;;

}