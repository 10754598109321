.userbadge {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .userbadge {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 431) {
  .userbadge {
    width: 25px;
    height: 25px;
  }
}
