.formButton {
  background-color: #0e80e8;
  max-width: 114px;
  height: 30px;
  color: #ffffff;
  border-radius: 15px;
  border: 0;
  width: 90%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  outline: 0;
}

.arrowIcon {
  width: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: absolute;
  right: 0;
}

.arrowIcon > img {
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
}

.btnText {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: center;
  width: 72px;
}

.modalItem {
  [class~="modal-dialog"] {
    max-width: 400px;
    margin-top: 10%;
  }

  /* [class~="modal-content"] {
    border: 1px;
    border-radius: 20.96px;
    background-color: #d9d9d9;
  } */
  /* [class~="modal-header"] {
    border-bottom: none;
    padding: 10px 1rem 0px 1rem;
  }
  [class~="modal-footer"] {
    border-top: none;
  }
  [class~="btn-close"] {
    font-size: 12px;
  }
  [class~="modal-body"] {
    padding: 0 1rem;
  }
  [type~="radio"] {
    outline: 0;
    margin-top: 2px;
  } */
}

.inputField {
  font-family: "Average Sans", sans-serif;
  background-color: #cbcaca;
  padding: 10px 15px;
  border: none;
  font-size: 14px;
  outline: 0;
  width: 100%;
  border-radius: 8px;
  margin: 10px 0 0px 0;
  height: 32px;
}

.error {
  font-family: "Average Sans", sans-serif;
  color: red;
  font-size: 11px;
  margin: 4px 21px 0px 15px;
}

.modalTitle {
  font-family: "Average Sans", sans-serif;
  font-size: 14px;
}

.modalTitleContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.modalHeader {
  display: flex;
  align-items: center;
  width: 100%;
}

.closeIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fieldHeading {
  font-family: "Average Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
}

.purchasebtnText {
  text-transform: UPPERCASE;
  width: 100%;
}

.purchaseformButton {
  background-color: #0e80e8;
  height: 58px;
  color: #ffffff;
  border-radius: 15px;
  border: 0;
  width: 90%;
  align-items: center;
  display: flex;
  justify-content: center;
  outline: 0;
}

.purchaseFooter {
  justify-content: center !important;
}

.purchseArrowIcon {
  width: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purchseArrowIconContainer {
  right: 11%;
  position: absolute;
}

.purchaseModalItem {
  [class~="modal-dialog"] {
    margin-top: 5%;
  }
}

.termLable {
  margin-left: 10px;
  cursor: pointer;
}

.termInnerText {
  font-weight: bold;
}

.hidden {
  display: none;
}

.purchaseformButton:disabled {
  cursor: not-allowed;
}

.termsSection {
  display: flex;
}

.inputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dialogItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: baseline;
  font-size: 14px;
  margin-bottom: 10px;
}

.dialogItem1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.dialogItemLabel {
  font-size: 14px;
  width: fit-content;
  padding-right: 7px;
}

.payoutBtn {
  border: 0;
  outline: 0;
  width: 66px;
  border-radius: 20px;
  margin-left: 10px;
  background-color: #6f1111;
  color: #fff;
}

.photoBtn {
  width: fit-content;
  font-size: 10px;
  border-radius: 5px;
  padding: 3px 4px;
  /* border: 0; */
  background-color: #fff;
  color: #000;
  /* background-color: #6f1111; */
  /* color: #fff; */
  display: flex;
  padding: 2%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  border: 1px solid #000;
}

.viewAppDialogItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.viewAppBtn {
  border: 0;
  outline: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 20px;
  height: 30px;
  width: 100%;
}

.viewBtn {
  background-color: #0e80e8;
}

.downloadAppBtn {
  background-color: #b69727;
}

.continueAppBtn {
  background-color: #6e1111;
}

.viewAppSubItem {
  width: 100%;
  text-align: center;
}

.viewAppBellIcon {
  margin-top: 30px;
  margin-bottom: 10px;
}

.alreadyText {
  font-size: 24px;
  font-weight: 400;
}

.toggleswitchcheckbox {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}
.toggleswitch {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  width: fit-content;
}

.switchtext {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: transparent;
  padding: 0 3px;
}
.active {
  padding: 0 3px;
  border: 1px solid #000;
  border-radius: 20px;
}

.currencyWrapper {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}

.currencyType {
  background-color: transparent;
  outline: 0;
  border-radius: 50%;
  border: 1px solid transparent;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.currencyTypeActive {
  border: 1px solid #000;
}

.amountWrapper {
  display: flex;
  gap: 4px;
  width: 100%;
  align-items: center;
}

.amountinput {
  background-color: transparent;
  border: 0;
  outline: 0;
  width: 110px;
  background-color: #cbcaca;
  /* padding-left: 9%; */
  border-radius: 8px;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 9%;
  /* padding: 2% 2% 0 9%; */

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.flex1 {
  flex: 1;
  flex-grow: 1;
}

.flex2 {
  flex: 2;
}

.descInput {
  background-color: rgb(203, 202, 202);
  border-radius: 5px;
  padding: 5px 10px;
  border: 0;
  outline: 0;
  color: rgb(37, 37, 37);
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.dnone {
  display: none;
}

.userWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  gap: 5px;
  max-height: 300px;
  overflow-y: auto;
}

.userimage {
  width: 35px;
  height: 35px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 1px solid #fff;
}

.userIconWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.posRelative {
  position: relative;
}

.iconCross {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: #cbcaca;
  color: #000;
  backdrop-filter: blur(10px);
  border-radius: 50%;
  cursor: pointer;
}

.submitBtnWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.saveBtn {
  border: 0;
  outline: 0;
  background-color: #cbcaca;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  padding: 4px;
}

.saveBtn > img {
  width: 15px;
  height: 15px;
  transform: scale(0.85);
}

.amountError {
  width: 100%;
  text-align: right;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .modalItem {
    [class~="modal-dialog"] {
      margin-top: 10%;
    }
  }

  .purchaseModalItem {
    [class~="modal-dialog"] {
      margin-top: 5%;
    }
  }
}

@media only screen and (max-width: 431px) {
  .modalItem {
    [class~="modal-dialog"] {
      margin-top: 20%;
    }
  }

  .purchseArrowIconContainer {
    right: 13%;
  }

  .purchaseModalItem {
    [class~="modal-dialog"] {
      margin-top: 5%;
    }
    padding-left: 6px !important;
  }

  .inputField {
    height: 30px;
  }

  .alreadyText {
    font-size: 20px;
    font-weight: 400;
  }
}


.userimage {
  width: 35px;
  height: 35px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 1px solid #fff;
}

.headerHeight {
  height: 50px;
  /* justify-content: center; */
}

.borderBottom{
  padding-bottom: 2%;
  border-bottom: 1px solid #A9A9A9;
}

.addspace{
  margin:2%;
}
