.mainDiv {
  width: 100%;
  min-height: inherit;
  background-color: #e6e6e6;
}

.dataContainer {
  max-width: 1222px;
  padding: 15px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 110px;
}
.wrapper {
  display: flex;
  gap: 15px;
}
.left {
  min-width: 350px;
  max-width: 350px;
  flex-shrink: 0;
}
.right {
  flex: 1;
  flex-grow: 1;
  padding: 10px;
  max-width: 800px;
}
.eventImg {
  width: 100%;
  margin: auto;
  /* height: 263px; */
  /* min-height: 263px; */
  object-fit: cover;
}

.boxes {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  color: #000;;
}

.iconDiv {
  width: 59px;
  height: 56px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 59px;
}
.iconDiv > img {
  width: 43px;
  height: 40px;
}
.infoDiv {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.producerDiv {
  width: 52px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e3c384;
  min-width: 59px;
  min-height: 59px;
}

.producerInfo {
  flex: 1;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.descDiv {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #000;
}

.purchase {
  max-width: 271px;
  margin: auto;
  width: 90%;
  height: 58px;
  background-color: #a493b7;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  position: relative;
  border: 0;
  outline: 0;
  box-shadow: 0 0 10px #6f7ec940;
  text-decoration: none;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  transition: all 0.3s ease;
}

.payoutBtn {
  max-width: 271px;
  margin: auto;
  width: 48%;
  height: 47px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  border: 0;
  outline: 0;
  box-shadow: 0 0 10px #6f7ec940;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.greenBtn {
  background-color: #007112;
  color: #fff;
}
 
.redBtn {
  background-color: #710000;
  color: #fff;
  margin-top: 1vh;
}

.purchase:hover {
  background-color: #fff;
  color: #7744b0;
}

.payoutBtn:hover {
  background-color: #fff;
}

.greenBtn:hover {
  color: #007112
}


.purchase:hover > .arrowIcon > img {
  transform: rotate(-45deg);
}


.arrowIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #7744b0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.commonArrowIcon {
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  /* right: 2px; */
  position: absolute;
  right: 2.5%;
}

.greenCommonArrrowIcon {
  background-color: #5B9865;
  color: #fff;
}

.redCommonArrrowIcon {
  background-color: #8D5757;
  color: #fff;
}

.redBtn:hover{
  color:#710000;
}



.cancelArrowIcon {
  background-color: #8D5757;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.commonArrowIcon > img {
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
}
.arrowIcon > img {
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
}
.date {
  font-size: 16px;
  font-weight: 500;
}
.timing {
  font-size: 12px;
  font-weight: 300;
}

.proName {
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalizes;
}

.desc {
  font-size: 20px;
  font-weight: 500;
}

.descDetail {
  font-size: 16px;
  font-weight: 300;
}

.eventName {
  font-size: 35px;
  font-weight: 500;
  color: #000;
}

.brand {
  font-size: 52px;
  font-weight: 300;
  letter-spacing: -5px;
  color: #fff;
}

.oneLogo {
  width: 65px;
  height: 65px;
}
.navHeader {
  width: 100%;
  height: 100px;
  /* border-radius: 0 0 10px 10px; */
  /* overflow: hidden; */
  overflow: visible;
  position: fixed;
  z-index: 5;
  background-color: #6f1111;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 62px;
}

.userCover {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
}

.backButton {
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #6f1111;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.iconCover {
  background-color: #bbb8b8;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0;
  outline: 0;
}
.pointer {
  cursor: pointer;
}
.eventName {
  margin-bottom: 12.5px;
}

.counterInput {
  width: 28px;
  height: 18px;
  border: 0;
  outline: 0;
  font-size: 12px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  cursor: no-drop;
  pointer-events: none;
}

.calcDiv {
  display: flex;
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.calcDiv p {
  font-size: 14px;
}
.calcDiv p:nth-child(2) {
  font-weight: 600;
}

.aboutEventDiv {
  padding: 8px 0;
}
.aboutEventText {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

.aboutEventDetail {
  font-size: 1rem;
  /* font-weight: 300; */
}
.labelText {
  font-size: 14px;
}

.skeletonImg {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 100%;
  height: 263px;
}

@keyframes skeleton-loading {
  0% {
    background-color: #e5e4e4;
  }
  100% {
    background-color: #d8d7d7;
  }
}

[class~="about-event-detail"] img {
  width: 100%;
}

.adminToolBtn {
  background-color: #ffffff;
  width: 162.74px;
  height: 30px;
  margin: auto;
  border-radius: 15px;
  border: 0;
  outline: 0;
  display: flex;
  /* display: none; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.checkIns {
  margin-bottom: 10px !important;
  border: 0;
  outline: 0;
  max-width: 331px;
  display: block;
  width: 85%;
  margin: auto;
  background-color: #eab9b4;
  font-size: 13px;
  font-weight: 400;
  color: #000;
  height: 35px;
  border-radius: 20px;
}

/* toggleSwitchStyles.css */
.toggleswitch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.6);
}

.toggleswitchCheckbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleswitchSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #50ac30;
  transition: 0.4s;
  border-radius: 34px;
}

.toggleswitchCheckbox:checked + .toggleswitchSlider {
  background-color: #50ac30;
}

.toggleswitchSlider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggleswitchCheckbox:checked + .toggleswitchSlider:before {
  transform: translateX(26px);
}

.switchWraper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fontLabel {
  font-family: "Average Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.adminToolBtnIcon {
  width: 24.31px;
  height: 21.48px;
}

.btnDiv {
  display: flex;
  gap: 15px;
}

.financialSection {
  border: 1px solid #b4b5b6;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  background-color: #ffffff;
  color: #000;
}

.financeItem {
  border-bottom: 1px solid #a9a9a9;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}

.payoutSection {
  margin-top: 20px;
}

.financeLbl {
  margin: 5px 10px;
}

.itemAmt {
  font-weight: 500;
}

.sendPayoutSection {
  text-align: right;
  margin: 10px 20px 0 20px;
}

.itemBtn {
  border: 0;
  outline: 0;
  background-color: #8058ae;
  color: #fff;
  font-size: 10px;
  border-radius: 5px;
  padding: 5px;
}

.itemBtnText {
  margin-left: 5px;
}

.itemBtnIcon {
  height: 13px;
  width: 22px;
}

.payNoteText {
  font-size: 8px;
  margin-left: 44%;
  text-align: left;
  margin-top: 3px;
}

.listContainer {
  margin: 0 20px 20px 20px;
}

.listHead {
  font-size: 14px;
}

.noExpense {
  font-size: 10px;
}

.expenseItem {
  display: flex;
  align-items: flex-start;
  margin-top: 3px;
  justify-content: space-between;
}

.expenseItemImg {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.expenseItemTitle {
  font-size: 12px;
}
.expenseItemSubTitle {
  font-size: 10px;
  margin-left: 10px;
}

.profileIcon {
  margin-top: 4px;
}

.expenseItemLeft {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
}

.expenseItemTotalLine {
  border: 1px solid #a9a9a9;
  margin: 5px 0;
}
.expenseItemTotal {
  display: flex;
  justify-content: flex-end;
  /* margin-right: 30px; */
  font-weight: 500;
}

.addExpenseBtn {
  background-color: #dcb16c;
  border: 0;
  outline: 0;
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  padding: 7px 8px;
  width: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addItemBtnText {
  margin-left: 4px;
}

.BtnContainer {
  margin-top: 5px;
}

.uniqueViewDiv {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .wrapper {
    flex-wrap: wrap-reverse !important;
  }
  .left {
    width: 100%;
    min-width: 100%;
  }
  .right {
    padding: 0;
    width: 100%;
    min-width: 100%;
  }

  .commonArrowIcon {
  
    width: 18%;

  }
}

.text1ExtraPAdding{
  padding-bottom: 16px;
}
@media only screen and (max-width: 768px) {
  .brand {
    font-size: 45px;
  }
  .oneLogo {
    width: 55px;
    height: 55px;
  }
  .userCover {
    width: 60px;
    height: 60px;
  }
  .payoutBtn{
    font-size: 12px;
  }
  .commonArrowIcon {
  
    width: 18%;

  }
}


@media only screen and (max-width: 431px) {
  .boxes {
    gap: 8px;
  }
  .infoDiv {
    padding: 5px;
  }
  .labelText {
    font-size: 12px;
  }
  .eventImg {
    height: auto;
    /* max-height: 250px; */
    /* min-height: 200px; */
  }

  .brand {
    font-size: 35px;
  }
  .oneLogo {
    width: 40px;
    height: 40px;
  }
  .userCover {
    width: 40px;
    height: 40px;
  }
  .eventName {
    font-size: 20px;
  }
  .aboutEventDetail {
    font-size: 12px;
  }
  form .descDetail {
    font-size: 12px;
    color: #000;
  }
  .payNoteText {
    margin-left: 47%;
  }
  .commonArrowIcon >img {
  
    width: 11px;
    height:11px

  }
}

@media only screen and (max-width: 325px) {
  .infoDiv {
    padding: 5px;
  }
  .brand {
    font-size: 35px;
  }
  .labelText {
    font-size: 12px;
  }
  .oneLogo {
    width: 40px;
    height: 40px;
  }
  .userCover {
    width: 40px;
    height: 40px;
  }
  .payNoteText {
    margin-left: 25%;
  }
  .payoutBtn{
    font-size: 10px;
    width: 70%;
  }
 
}

/* style for admin tools */
.wfull {
  width: 100%;
}

.outline {
  outline: 0 !important;
}

.pointernone {
  pointer-events: none;
}

.bgGray {
  background-color: #d1d1d1;
}

.bgTransparent {
  background-color: transparent !important;
  border: 0;
  outline: 0;
}

.border {
  border: 1px solid #a9a9a9;
}

.textBlack {
  color: #000000;
}

.elipsisTicket {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customTextarea {
  width: 100%; /* Adjust width as needed */
  min-height: 70px; /* Adjust height as needed or make it auto */
  resize: none; /* Prevents resizing */
  overflow: hidden; /* Keeps the content from overflowing */
  outline: none; /* Removes the outline on focus */
  border: 1px solid #ccc; /* Mimics typical input border; adjust as needed */
  border-radius: 4px; /* Rounds the corners slightly; adjust as needed */
  padding: 8px; /* Adds some padding inside the textarea; adjust as needed */
}

.paddingMargin0 {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  outline: 0 !important;
}
.paadingX7 {
  padding: 0px 7px;
}
.flexGrow1 {
  flex-grow: 1;
}

.borderOutline {
  border: 0 !important;
  outline: 0 !important;
}

.height {
  height: 100% !important;
}

.borderRadius10 {
  border-radius: 10px;
}

.posRelative {
  position: relative;
}

.dNone {
  display: none;
}

.top10 {
  top: 20px;
}

.left10 {
  left: 20px;
}

.posAbsolute {
  position: absolute;
}

.aboutEventDiv::-webkit-scrollbar {
  width: 5px;
}

.aboutEventDiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(46, 43, 43, 0.3);
}

.aboutEventDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #3d3131;
  outline: 1px solid slategrey;
}

.aboutEventStyle {
  height: 500px;
  overflow-y: auto;
  padding-inline: 7px;
}

/* add ticket modal css */

.modalTicketHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.headerTitle {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 32.69px;
}

.cardWrapper img:nth-child(1) {
  width: 32px !important;
  height: 27px !important;
}
.cardWrapper img:nth-child(2) {
  width: 79px !important;
  height: 92px !important;
}
.cardWrapper div div div:last-child img {
  width: 14px !important;
  height: 14px !important;
}

.cardWrapper div div:nth-child(1) {
  font-size: 13px !important;
  line-height: 17.71px !important;
  font-weight: 300 !important;
}
.cardWrapper div div:nth-child(2) {
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 20.43px !important;
}

.cardWrapper div div div:last-child {
  gap: 7.5px !important;
}
.cardWrapper div div div:last-child div {
  font-size: 13px !important;
  line-height: 17.71px !important;
  font-weight: 300 !important;
}
.cardWrapper > div {
  min-height: auto !important;
  gap: 8px !important;
  width: 97% !important;
  margin: auto !important;
  padding: 5px !important;
  border-width: 3px !important;
}

/* //edit ticket header text style` */

.formTicketEdit {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editTicketHeader {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}

.boxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.inputStyliing {
  background-color: #d1d1d1;
  border: 1px solid #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  padding-inline: 15px;
  border-radius: 10px;
  min-height: 41px;
}

.currencyDiv > input {
  padding-left: 25px !important;
}

.currency {
  position: absolute;
  top: 62%;
  transform: translateY(-25%);
  left: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}

.saveBtn {
  background-color: #684f85;
  border: 0;
  outline: 0;
  width: 90%;
  margin: auto;
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  height: 58px;
  border-radius: 20px;
  text-transform: uppercase;
  position: relative;
  margin-top: 20px;
}

.saveIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  background-color: #a493b7;
  border-radius: 50%;
  padding: 5px;
}

.saveIcon > img {
  width: 23px;
  height: 23px;
}

.wModal {
  max-width: 363px !important;
}

.refer .module-dialog {
  max-width: 363px !important;
}

.datePickerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.datePickerWrapper img {
  margin-right: 10px;
}

.datePickerWrapper input {
  background-color: transparent;
  border: 0;
  outline: 0;
  /* display: none; */
}

.modalBackground{
  background-color: #fff;
  min-height: 50vh;
}

.confirmationText1{
  text-align: center;
  font-weight:400;
  font-size:20px;
  margin: 0;
}



.confirmationText2{
  font-weight:400;
  font-size:15px;
  text-align: center
}

.sendLayoutHeader {
  /* padding: none; */
  visibility: none;
}

.cancelEvent {
  background-color: transparent;
    color: #fff;
    border: none;
    color: #000;
}

.modalContainer{
  margin: 10%;
}

/* #aboutEvent > svg */


#aboutEvent .ql-snow path{
  stroke:#000
}


#aboutEvent .ql-snow line {
  stroke:#000
}
#aboutEvent .ql-snow rect {
  stroke:#000
  /* stroke: #fff !important; */
}

#aboutEvent .ql-snow polyline {
  stroke:#000
  /* stroke: #fff !important; */
}

.rsvpBtnSelection{
  /* width: 75%; */
  /* width: 53%; */
  background-color: #DB9791  ;
  padding: 2% 5%;
  display: flex;
  justify-content: space-around;
  border-radius: 2%;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  /* display: flex; */
  /* gap:3% */
}

.selectBtn{
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1rem;
  background-color: #EAB9B4;
  padding:1% 5%;
  border-radius: 7px;
}

.greenBorder{
  border:2px solid #457A24;
  padding:1% 5%;
  border-radius: 7px;
}

.rsvpContaner{
  background-color: #fff;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  margin-top: 5%;
}

.rsvptxt{
  color: rgb(33, 33, 33);
  font-weight: 600;
}

.RsvpNumber{
  color: rgb(33, 33, 33);
  font-weight: 600;
  font-size: 1rem;
}

.rsvpHeader{
  display: flex;
  justify-content: space-between;
  /* padding: 0px 4%; */
  padding: 4% 12% 4%;
}

.RsvpSubText{
  color: rgb(33, 33, 33);
  font-weight: 600;
  font-size: 0.7rem;
}

.userImg{
  width:8vw;
  height:8vw;
  border-radius: 100%;
  margin: 2%;
}

.imgContainer{
  width:8vw;
  height:8vw;
  border-radius: 100%;
  position: relative;
  
}

.starImg{
  /* width: 5vw;
  height: 5vh;
  top: 0;
  right: 0;
  position: absolute; */


  position: absolute;
  top: 5vw;
  right: 1.5vw;
  width:2vw;
  height:2vw;;
  background-color: #cbcaca;
  color: #000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  cursor: pointer;
  z-index: 10 !important;
  
}


@media screen and (min-width: 768px){
  .userImg{
    width: 4vw;
    height: 4vw;
   
  }
  
  .imgContainer{
    width: 4vw;
    height: 4vw;
    border-radius: 100%;
    position: relative;
    
  }
  
  .starImg{
    /* width: 5vw;
    height: 5vh;
    top: 0;
    right: 0;
    position: absolute; */
  
  
    position: absolute;
    top: 1.8vw;
    right: 0.8vw;
    width:1vw;
    height:1vw;;
    background-color: #cbcaca;
    color: #000;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    cursor: pointer;
    z-index: 10 !important;
    
  }

  .rsvpBtnSelection{
    width: 95%;
    padding: 3% 5%;
   
  }

}




