.mainDiv {
  width: 100%;
  min-height: inherit;
  /* background-color: #e6e6e6; */
  display: flex;
  flex-direction: column;
}

.formContainer {
  margin: 200px auto auto auto !important;
  width: 100%;
  min-width: 500px;
  max-width: 500px;
}

@media only screen and (max-width: 431px) {
  .formContainer {
    width: 90%;
    min-width: revert;
    max-width: revert;
    margin: 150px auto auto auto !important;
  }
}
