.card {
  padding: 15px;
  position: relative;
  width: 300px;
  border-top: 2px solid #7a40f2f2 !important;
  border-radius: 20px !important;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #c6b6e8f2;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 0px;
  background-color: #7a40f2f2;
  transition: all 0.3s ease;
  opacity: 0.5;
}

.card:hover{
  box-shadow: 0 0 5px #7A40F2F2;
  color: #000;
}

.card:hover::before {
  height: 100%;
  opacity: 0.3;
}
