.cardMainDiv {
  max-width: 500px;
  width: 100%;
  min-height: 140px;
  background-color: #fff;
  margin: auto;
  border-radius: 16px;
  border: 2px solid #db9791;
  padding: 10px;
  position: relative;
  display: flex;
  gap: 20px;
  cursor: pointer;
  overflow: hidden;
  color: #212529;
}
.tent {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  width: 38px;
  height: 32px;
}
.heroImage {
  width: 140px;
  height: 140px;
  border-radius: 10px;
  flex-shrink: 0;
  object-fit: cover;
}
.infoDiv {
  flex: 1 1 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.dateTime {
  font-size: 16px;
  font-weight: 300;
  line-height: 17.71px;
}
.eventName {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.addWrapper {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.locationPin {
  width: 18px;
  height: 18px;
}

.address {
  font-size: 13px;
  font-weight: 300;
  max-width: 182px;
  height: inherit;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto;
}

.cancelText{
  right: 10px;
  margin-top: 52px;
  color:#FF0000
}

@media only screen and (max-width: 768px) {
  .cardMainDiv {
    width: 95%;
    margin: auto;
    gap: 10px;
    min-height: auto;
    padding: 7.5px;
  }
  .infoDiv {
    flex: 1 1 auto;
    padding: 0 10px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto;
  }
  .heroImage {
    width: 130px;
    height: 140px;
    flex-shrink: 0;
  }
  .tent {
    width: 42px;
    height: 35px;
  }

  .cancelText{
    right: 10px;
    margin-top: 38px
  }
  
  .dateTime {
    line-height: 17.71px;
    font-weight: 400;
  }
  .eventName {
    font-weight: 600;
    line-height: 20.43px;
  }
  .address {
    line-height: 17.71px;
    font-weight: 400;
  }
  .addWrapper {
    gap: 10px;
    font-size: 13px;
    font-weight: 300px;
    margin-top: 48px;
  }
}

@media only screen and (max-width: 431px) {
  .cardMainDiv {
    max-width: 327px;
    width: 95%;
    margin: auto;
    gap: 10px;
    min-height: auto;
    padding: 7.5px;
  }
  .cardMainDiv {
    width: 95%;
    margin: auto;
  }

  .tent {
    width: 32px;
    height: 27px;
  }
  .heroImage {
    width: 79px;
    height: 92px;
    flex-shrink: 0;
  }
  .dateTime {
    font-size: 13px;
    line-height: 17.71px;
    font-weight: 300;
  }
  .eventName {
    font-size: 15px;
    font-weight: 500;
    line-height: 20.43px;
  }

  .address {
    font-size: 13px;
    line-height: 17.71px;
    font-weight: 300;
  }
  .infoDiv {
    flex: 1 1 auto;
    padding: 0 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .locationPin {
    width: 14px;
    height: 14px;
  }
  .addWrapper {
    gap: 7.5px;
  }
  .cancelText{
    right: 10px;
    font-size: 13px;
    font-weight: 300px;
    margin-top: 48px;
  }
}
