.wrapper {
  display: flex;
  flex-direction: column;
}

.maindiv {
  width: 100%;
  min-height: inherit;
  padding: 0;
  /* margin-top: 45px; */
}

.userJoined {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.userWraper {
  max-width: 1222px;
  padding: 0 5px;
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

.buttonPrevNext {
  background-color: #fff;
  color: #194747;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
}

@media only screen and (max-width: 768px) {
  .userWraper {
    gap: 4px;
    justify-content: flex-start;
    padding: 0px 4px;
  }
}
@media only screen and (max-width: 431px) {
  .userWraper {
    gap: 4px;
    padding: 6px 4px;
    justify-content: flex-start;
  }
}

.userJoined::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.userJoined {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
.userWraper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.userWraper {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.headerdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0px 20px;
  color: #aa8de5f2;
  background-color: #171717;
  position: fixed;
  z-index: 999;
}

.cardstyle {
  height: auto;
  border-radius: 20px;
  margin: 15px;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  background-color: #7a40f2f2;
  color: #fff;
  width: 90%;
  margin: auto;
}

.logoBox {
  height: 100px;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.logoBox h3 {
  font-size: 20px;
  font-weight: 600;
  color: #7a40f2f2;
  display: flex;
  padding-top: 15px;
  gap: 3px;
  transition: all 0.6s ease;
  white-space: nowrap;
}

.logoBox .logoIcon {
  color: #fff;
  border: 1px solid #7a40f2f2;
  width: 1.5rem;
  height: 1.5rem;
  padding: 4px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}

.logoBox h3 p {
  gap: 3px;
  align-items: center;
  white-space: nowrap;
}

.logoBox h3 span {
  color: #fff;
  font-weight: 800;
}

.infinitescroll {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom:90px ;
  /* padding-bottom: 90px; */
}
.p {
  display: flex;
}
.headertitle {
  margin-bottom: 0;
}
.filterdiv {
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* padding: 20px; */
  justify-content: space-evenly;
  align-items: center;
  /* min-height: 82px; */
  flex-wrap: wrap;
  /* border: 1px solid #db9791; */
  border-radius: 15px;
  /* border-bottom: 5px solid #db9791; */
  position: sticky;
  top: 100px;
  z-index: 5;
  transition: 0.5s ease;
  /* background-color: #fff; */
  transition: all 0.6s ease;
  width: 95%;
  margin-inline: auto;
  color: #fff;
}

.datediv {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: column;
  min-height: 82px;
}

.datediv > span {
  font-weight: 600;
  color: #171717;
}

.searchbtn {
  width: 100px;
  border: 0;
  outline: 0;
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #db9791;
  color: #fff;
  font-weight: 600;
  height: 40px;
  transition: all 0.5s ease;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
}

.searchbtn:hover {
  background-color: #171717;
  color: #db9791;
  border: 1px solid #db9791;
}

.date_picker {
  border: 1px solid #db9791;
  outline: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #fff;
}

.date_picker:focus {
  background-color: #fff;
  border: 2px solid #db9791;
}

.searchdiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 82px;
}

.navHeader {
  width: 100%;
  height: 100px;
  /* border-radius: 0 0 10px 10px; */
  /* overflow: hidden; */
  overflow: visible;
  position: fixed;
  z-index: 5;
  background-color: #003333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 62px;
  z-index: 999;
}
.brandText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-grow: 1;
  flex-shrink: 0;
}
.upperHeader {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
.brand {
  font-size: 52px;
  font-weight: 300;
  letter-spacing: -5px;
  color: #fff;
  flex-shrink: 0;
  font-family: "Noto Sans", sans-serif;
  line-height: 52px;
}

.userCover {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
}

.oneLogo {
  width: 65px;
  height: 65px;
}
.filterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* background: rgba(255, 255, 255, 0.25); */
  background: #194747;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 8px 8px;
}

.filter {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  font-size: 14px;
  width: 100%;
}

.filter::placeholder {
  color: #fff;
  font-size: 14px;
}
.brandText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 80%;
}
.upperHeader {
  flex-basis: 50%;
}

.clearIcon {
  cursor: pointer;
  color: #fff;
  position: absolute;
  display: flex;
  right: 10px;
}

.navLink {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
}

.eventSticky {
  min-width: 500px;
  max-width: 500px;
  margin: auto;
  background-color: #f6b716;
  padding-left: 20px;
  border-radius: 20px;
}

.mainLabel {
  font-size: 20px;
  color: #fff;
}
.subLabel {
  font-size: 12px;
  color: #fff;
  padding-left: 10px;
}

.subBrand {
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 400;
  font-family: "Average Sans";
  margin-top: -6px;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .headerdiv {
    flex-wrap: wrap;
  }
  .brand {
    font-size: 45px;
  }
  .navHeader {
    padding: 0 30px;
    flex-wrap: wrap-reverse;
  }
  .userCover {
    width: 60px;
    height: 60px;
  }
  .oneLogo {
    width: 55px;
    height: 55px;
  }
  .filterContainer {
    width: 150px;
    flex-basis: auto;
    margin: auto;
    justify-content: flex-start;
    /* margin-right: 10px; */
  }
  .upperHeader {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
  .brandText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 431px) {
  .filterdiv {
    margin-top: 110px;
    position: unset;
    gap: 10px;
  }
  .headertitle {
    padding: 10px 0 30px 0;
  }

  .brand {
    font-size: 30px;
  }
  .brandText {
    align-items: flex-end;
  }

  .subBrand {
    font-size: 10px;
    margin-top: -15px;
  }

  .filter::placeholder {
    font-size: 10px;
  }
  .filter {
    font-size: 10px;
  }
  .navHeader {
    padding: 20px 10px;
    height: auto;
    gap: 10px;
    flex-wrap: wrap-reverse;
  }
  .filterContainer {
    width: 150px;
    flex-basis: auto;
    margin: auto;
    justify-content: flex-start;
    padding: 4px 4px;
    gap: 4px;
  }
  .userCover {
    width: 40px;
    height: 40px;
  }
  .oneLogo {
    width: 40px;
    height: 40px;
  }
  .filterdiv {
    gap: 0;
    flex-wrap: wrap;
  }
  .searchdiv {
    min-height: fit-content;
  }
  .datediv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: auto;
    padding-top: 7px;
  }
  .datediv > span {
    font-size: 15px;
    text-align: left;
    width: 200px;
  }
  .date_picker {
    border-radius: 10px;
    padding: 3.5px 7.5px;
    background-color: #fff;
    font-size: 15px;
  }
  .searchbtn {
    margin-top: 10px;
    font-size: 15px;
    padding: 7.5px 12px;
  }
  .brandText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .eventSticky {
    width: 95%;
    min-width: 95%;
  }
}
