.btnWrapper {
    width: 20vw;
    padding: 1% 2%;
    background-color: #DABD84;
    border-radius: 18px;
    border: none,
}

@media only screen and (max-width: 600px) {
    .btnWrapper{
        width: 50vw;
    }
  }
  