.passwordContainer {
  width: 100%;
}

.inputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
