.btn {
  height: 40px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  width: 50%;
  background-color: #171717;
  border: 0;
  outline: 0;
  cursor: pointer;
  box-shadow: 2px 2px 0px #fff;
  transition: all 0.4s ease;
}

.btn:hover{
    box-shadow: 0px 0px 0px #fff;
    transform: translateX(2px) translateY(2px);
}


